<template>
  <div class="das_top_cont">
    <div class="history_box account_input">
      <div class="accountright_cont_box">
        <div class="flex_center_between_box">
          <div style="display:flex;padding-top: 10px;">
            <h2 class="history_box-head">
              <el-badge :value="RedNews.length" :hidden="RedNews.length == 0" class="item" type="warning">
                Notifications
              </el-badge>
            </h2>
            <div class="history_box-head-right flex_center">
              <svg t="1723168823188" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="5443" width="1.2857142857142858rem"
                height="1.2857142857142858rem">
                <path
                  d="M921.6 512h-41l92.2 460.8c0 28.3-22.9 51.2-51.2 51.2H102.4c-28.3 0-51.2-22.9-51.2-51.2L143.4 512h-41c-28.3 0-51.2-22.9-51.2-51.2v-51.2c0-28.3 22.9-51.2 51.2-51.2h307.2v-256C409.6 45.8 455.4 0 512 0s102.4 45.8 102.4 102.4v256h307.2c28.3 0 51.2 22.9 51.2 51.2v51.2c0 28.3-22.9 51.2-51.2 51.2zM102.4 972.8h155.2c-3.7-5.5-5.2-12.1-4.1-18.6l31.2-227.6c1.9-14 14.8-23.8 28.8-21.9 14 1.9 23.8 14.8 21.9 28.8-0.1 0.6-0.2 1.3-0.3 1.9l-31 227.6c-0.6 3.5-1.9 6.8-3.9 9.7h621.4L819.2 512H204.8L102.4 972.8z m460.8-870.4c0-28.3-22.9-51.2-51.2-51.2s-51.2 22.9-51.2 51.2v256h102.4v-256z m358.4 307.2H102.4v51.2h819.2v-51.2z"
                  fill="#1A2332" p-id="5444"></path>
              </svg>
              <span class="cleara" @click="MarkRead()"> Mark as Read </span>

            </div>
          </div>
          <div class="sel_box computer_right_cont_box">
            <el-input autocomplete="off" class="inline-input" suffix-icon="el-icon-search" v-model="Contentval"
              placeholder="Enter Title Content" @change="NewInput()"></el-input>
            <!-- suffix-icon="el-icon-search" -->
          </div>
        </div>
        <div class="faq_box">
          <div class="">
            <el-collapse v-model="activeNames" @change="Changered">
              <!-- JSON.string(item) -->
              <el-collapse-item class="item_title" v-for="(item, index) in Newlist" :key="index"
                :name="JSON.stringify(item)">
                <template slot="title">
                  <div class="flex_center_between_box" style="width: 100%;">
                    <div class="flex_center_between_box">
                      <img src="../../../assets/img/csi头像.png" alt="" />
                      <div class="message-right">
                        <div class="head-text">
                          <!-- <span class="icon-time head-text"></span> -->
                          {{ item.title }}
                        </div>
                        <div class="icon-time">{{ item.createTime }}</div>
                      </div>
                    </div>
                    <div v-show="item.hasRead==0" class="greea-cre"></div>
                  </div>
                </template>
                <div class="item_cont_txt">
                  {{ item.content }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNotificationList, readNotification, markAllAsRead, searchByTitle } from '@/api/userApi.js';
export default {
  data() {
    return {
      activeNames: [1],
      attentionDialog: false, //弹框
      Contentval: "",
      RedNews: [],
      Newlist: [{
        logo: '',
        title: '',
        content: '',
        hasRead: '',
        createTime: ''

      }]
    };
  },
  computed: {},

  created() {
    this.getNotificationListDetali()
  },
  methods: {
    Changered(item) {

      this.$store.dispatch("app/SetNotificationsList", []);
      let arr = item
      if (arr.length > 1) {
        arr.splice(0, 1);

      }
      if (JSON.parse(arr).hasRead == 0) {
        readNotification({ id: JSON.parse(arr).id }).then(res => {
          if (res.code == 200) {

            this.getNotificationListDetali()
          }
        })
      }


  
    },
    NewInput() {
      searchByTitle({ titleKey: this.Contentval }).then(res => {
        if (res.code == 200) {
          this.Newlist = res.data


          if (this.Newlist.length > 0) {
            res.data.map(item => {
              if (item.hasRead == 0) {
                this.RedNews.push(item)
              }
            })
          }


        } else {
          this.Newlist = []
        }

      })

    },
    MarkRead() {
      markAllAsRead().then(res => {
        if (res.code == 200) {
          this.getNotificationListDetali()
        }
      })
    },

    getNotificationListDetali() {
      getNotificationList().then(res => {
        if (res.code == 200) {
          this.Newlist = res.data

          if (this.Newlist.length > 0) {
            this.RedNews = this.Newlist.filter(item => item.hasRead == 0)
            this.$store.dispatch("app/SetNotificationsList", this.RedNews);

          }
        } else {

          this.Newlist = []
        }

      })
    }
  },
};
</script>
<style scoped>
.title {
  font-family: 'DMSans Bold';
  margin-top: 1.5714285714285714rem;
  margin-bottom: 1.4285714285714286rem;
}

.el-collapse {
  border: none !important;
  font-family: "Arial";
}

.item_title /deep/ .el-collapse-item__header {
  font-size: 1.1428571428571428rem;
  color: #022955;
  font-family: "Arial";
  /* margin: 0.7142857142857143rem 0; */
  background-color: #f5f5f5;
  border: none !important;
}

.item_title {
  padding: 1.4285714285714286rem;
  background-color: #f5f5f5;
  margin-bottom: 0.7142857142857143rem;
  padding-right: 0.7142857142857143rem;
}

.item_title.el-collapse-item.is-active:last-child /deep/ .el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
  border-width: 0;
  border: none !important;
}

.item_title /deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}

.item_cont_title {
  /* font-family: "Arial Bold"; */
  font-family: "Arial";
  /* margin-bottom: 0.7142857142857143rem; */
  color: #022955;
}

.item_cont_txt {
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  padding-left: 5rem;
  background-color: #f5f5f5;
  padding-top: 1.1428571428571428rem;
}

.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}

.item_title /deep/.el-collapse-item__content {
  padding-bottom: 0;
}

/* .item_title /deep/ .el-collapse-item__arrow {
    width: 1.7142857142857142rem;
    height: 1.7142857142857142rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.6428571428571429rem;
} */
/* .item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}
*/
.item_title /deep/ .el-collapse-item__arrow {
  transform: rotate(90deg);
}

.item_title /deep/ .el-collapse-item__arrow.is-active {
  transform: rotate(-90deg);
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

.link {
  color: #1290c9;
  text-decoration: underline;
}

.head-text {
  font-family: 'DMSans Bold';
  font-size: 1.4285714285714286rem !important;
  text-align: left;
  font-style: normal;
  color: #1a2332;
  line-height: 1.6428571428571428rem;
}

.icon-time {
  font-size: 1.1428571428571428rem;
  line-height: 1.6428571428571428rem;
  color: #999999;
  margin-top: 0.5714285714285714rem;
  height: 1.5714285714285714rem;
}

.message-right {
  margin-left: 1.4285714285714286rem;
}

.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.1428571428571428rem;
}

/deep/ .el-badge__content--warning {
  background-color: #55B419;
  top: 1.2142857142857142rem !important;
  right: -0.35714285714285715rem !important;
  height: 1.8571428571428572rem;
  width: 1.8571428571428572rem;
  border-radius: 50%;
  font-size: 1.1428571428571428rem;
  padding: 0rem !important;
  line-height: 1.6428571428571428rem;
}

.cleara {
  font-size: 1.1428571428571428rem;
  color: #1A2332;
  text-decoration-line: underline;
  margin-left: 0.5714285714285714rem;
  margin-top: 0.14285714285714285rem;
}

.history_box-head-right {
  margin-left: 4.285714285714286rem;
}

/deep/.el-collapse-item__wrap {
  border: none !important
}

.greea-cre {
  width: 0.7857142857142857rem;
  height: 0.7857142857142857rem;
  background: #55B419;
  border-radius: 50%;
  /* position: relative;
  top: -1.5rem;
  left: -0.6428571428571429rem; */
}

@media screen and (max-width: 1441px) {
  /* 小绿点 */
  .greea-cre {
    width: 12px;
    height: 12px;
  }

  /* 时间，内容，箭头 */
  .icon-time,
  .item_cont_txt,
  .item_title /deep/ .el-collapse-item__header {
    font-size: 12px;
  }


}

/* 特殊处理 */
.account_input .sel_box {
  margin-right: 0;
}

.account_input .accountright_cont_box .flex_center_between_box {
  margin-bottom: 0;
}

.el-input .el-icon-search:before {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  background: #1A2332;
}
</style>